import { useState, useEffect } from "react";
import ManpowerList from "./ManpowerList";
import HeadMaster from "./HeadMaster";
import Footer from "./Footer";
import Students from "./Students";
import Notice from "./Welcome";
import GifImg from "./GifImg";
import PhotoAndNotice from "./PhotoAndNotice";
import History from "./History";
import Contact from "./Contact";
import Admission from "./Admission";

import Login from "./LogIn";
import Rutin from "./Rutin";
import AuthDetails from "./../components/AuthDetails";
import { auth } from "../firebase";
import { onAuthStateChanged } from "firebase/auth";
import Dashboard from "./Dashboard";

import Result from "./Result";
import PbNotice from "./PbNotice";

function Header() {
  const [toggle, setToggle] = useState(1);
  const [open, setOpen] = useState(false);
  const [authUser, setAuthUser] = useState(null);

  useEffect(() => {
    const listen = onAuthStateChanged(auth, (user) => {
      if (user) {
        setAuthUser(user);
      } else {
        setAuthUser(null);
      }
    });

    return () => {
      listen();
    };
  }, []);

  function updateToggle(id) {
    setToggle(id);
    setOpen(false);
  }
  return (
    <div>
      <div className="px-3 lg:px-[60px] xl:px-[100px] max-w-[1600px] mx-auto">
        <div className="bg-gradient-to-r from-blue-900 to-violet-900 md:text-base text-sm py-2 px-4 flex items-center justify-between text-white">
          <AuthDetails />

          <div className="flex items-center gap-4">
            <p>{new Date().toLocaleDateString()}</p>
          </div>
        </div>

        <div className="flex justify-end"></div>
        <div className="py-4 bg-green-900 text-white justify-center flex flex-col md:flex-row items-center md:gap-4">
          <img
            src="logo.jpg"
            className="w-20 mb-2 md:mb-0 md:w-32 h-20 md:h-32 shadow-md "
            alt=""
          />
          <div className="text-center md:text-start">
            <p className="font-bold text-2xl md:text-4xl lg:text-5xl">
              অগ্রনী উচ্চ বিদ্যালয়, সয়া-চাক্‌তা, টাঙ্গাইল সদর
            </p>
            <div className="bg-gray-300 my-2 h-[1px] w-full"></div>
            <p className="text-white text-base md:text-xl lg:text-3xl uppercase font-bold">
              Agrani High School, Soya-chakta, Tangail Sadar
            </p>
            <p className="text-gray-200 text-base md:text-xl uppercase font-bold">
              EIIN : 114711, MPO CODE : 4211071301
            </p>
          </div>
        </div>
        <div>
          <div
            onClick={() => setOpen(!open)}
            className="md:hidden block bg-blue-900 py-2 text-white text-2xl cursor-pointer px-4">
            <ion-icon name={open ? "close-outline" : "menu-outline"}></ion-icon>
          </div>
          <div
            className={`${
              open ? "block" : "hidden"
            } bg-blue-700 md:bg-blue-900 duration-500 flex md:flex md:flex-row flex-col justify-center gap-2 lg:gap-8 md:items-center text-white`}>
            <a
              onClick={() => updateToggle(1)}
              href="/"
              className={`${
                toggle === 1 ? "active" : null
              } hover:bg-blue-500 py-3 px-4 `}>
              মূলপাতা
            </a>

            <p
              onClick={() => updateToggle(2)}
              className={`${
                toggle === 2 ? "active" : null
              } cursor-pointer hover:bg-blue-500 py-3 px-4 `}>
              জনবল
            </p>

            <p
              onClick={() => updateToggle(3)}
              className={`${
                toggle === 3 ? "active" : null
              } cursor-pointer hover:bg-blue-500 py-3 px-4`}>
              শিক্ষার্থীদের তথ্য
            </p>
            <p
              onClick={() => updateToggle(4)}
              className={`${
                toggle === 4 ? "active" : null
              } cursor-pointer hover:bg-blue-500 py-3 px-4`}>
              ভর্তি সংক্রান্ত তথ্য
            </p>
            <p
              onClick={() => updateToggle(5)}
              className={`${
                toggle === 5 ? "active" : null
              } cursor-pointer hover:bg-blue-500 py-3 px-4`}>
              ক্লাস রুটিন
            </p>
            <p
              onClick={() => updateToggle(6)}
              className={`${
                toggle === 6 ? "active" : null
              } cursor-pointer hover:bg-blue-500 py-3 px-4`}>
              পরীক্ষার ফলাফল
            </p>
            <p
              onClick={() => updateToggle(7)}
              className={`${
                toggle === 7 ? "active" : null
              } cursor-pointer hover:bg-blue-500 py-3 px-4`}>
              যোগাযোগ
            </p>
            <p
              onClick={() => updateToggle(8)}
              className={`${
                toggle === 8 ? "active" : null
              } cursor-pointer hover:bg-blue-500 py-3 px-4`}>
              লগ ইন
            </p>
            <p
              onClick={() => updateToggle(9)}
              className={`${
                toggle === 9 ? "active" : null
              } cursor-pointer hover:bg-blue-500 py-3 px-4 ${
                authUser ? "block" : "hidden"
              }`}>
              ড্যাশবোর্ড
            </p>
          </div>
        </div>

        <div className={toggle === 1 ? "block" : "hidden"}>
          <div className="py-3 md:py-4 lg:py-6"></div>
          <Notice />
          <div className="py-3 md:py-4 lg:py-6"></div>
          <GifImg />
          <div className="py-3 md:py-4 lg:py-6"></div>
          <div className=" my-4 flex md:flex-row flex-col justify-between gap-4 md:pr-4 py-3 md:py-4 lg:py-6 bg-white">
            <div className="md:w-[70%]">
              <PhotoAndNotice />
            </div>
            <div className="border p-1 border-gray-300 shadow-md md:w-[30%] ">
              <p className="text-xl mt-4 rounded-md md:mt-0 md:text-2xl lg:text-3xl bg-black text-white py-1 text-center">
                নোটিশ বোর্ডঃ
              </p>
              <PbNotice />
            </div>
          </div>
          <div className="py-3 md:py-4 lg:py-6"></div>
          <HeadMaster />
          <div className="py-3 md:py-4 lg:py-6"></div>
          <History />
        </div>
        <div className={toggle === 2 ? "block" : "hidden"}>
          <div className="py-3 md:py-4 lg:py-6"></div>
          <ManpowerList />
        </div>
        <div className={toggle === 3 ? "block" : "hidden"}>
          <div className="py-3 md:py-4 lg:py-6"></div>
          <Students />
        </div>
        <div className={toggle === 4 ? "block" : "hidden"}>
          <div className="py-3 md:py-4 lg:py-6"></div>
          <Admission />
        </div>
        <div className={toggle === 5 ? "block" : "hidden"}>
          <div className="py-3 md:py-4 lg:py-6"></div>
          <Rutin />
        </div>
        <div className={toggle === 6 ? "block" : "hidden"}>
          <div className="py-3 md:py-4 lg:py-6"></div>
          <Result />
        </div>
        <div className={toggle === 7 ? "block" : "hidden"}>
          <div className="py-3 md:py-4 lg:py-6"></div>
          <Contact />
        </div>
        <div className={toggle === 8 ? "block" : "hidden"}>
          <div className="py-3 md:py-4 lg:py-6"></div>
          <Login />
        </div>
        <div className={toggle === 9 ? "block" : "hidden"}>
          <div className="py-3 md:py-4 lg:py-6"></div>
          <Dashboard />
        </div>
      </div>
      <div className="py-3 md:py-4 lg:py-6"></div>
      <Footer />
    </div>
  );
}

export default Header;
