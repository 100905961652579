export default function ManagingCommittee() {
  return (
    <div>
      <p className="text-xl md:text-2xl lg:text-3xl font-bold py-4 text-gray-600">
        বর্তমান পরিচালনা কমিটিঃ
      </p>
      <div className="grid lg:grid-cols-2 gap-2">
        <div className="bg-white py-2 px-4 flex md:flex-row items-center rounded-xl flex-col gap-4">
          <img
            src="teachers/ali.png"
            className="w-36 shadow-md object-cover h-[180px] md:h-full border mx-auto border-gray-400"
            alt=""
          />
          <div className="rounded p-2 w-full">
            <p className="text-xl font-semibold">মোঃ আলী হোসেন</p>
            <div className="bg-gray-300 w-full h-[1px]"></div>
            <div className="text-gray-600">
              <p>পদবী : সভাপতি</p>
              <p>মোবাইল : </p>
            </div>
          </div>
        </div>
        <div className="bg-white py-2 px-4 flex md:flex-row items-center rounded-xl flex-col gap-4">
          <img
            src="teachers/ronjit.jpg"
            className="w-36 shadow-md object-cover h-[180px] md:h-full border mx-auto border-gray-400"
            alt=""
          />
          <div className="rounded p-2 w-full">
            <p className="text-xl font-semibold">রনজিৎ চন্দ্র ধর</p>
            <div className="bg-gray-300 w-full h-[1px]"></div>
            <div className="text-gray-600">
              <p>পদবী : সাধারণ শিক্ষক সদস্য</p>
              <p>মোবাইল : </p>
            </div>
          </div>
        </div>
        <div className="bg-white py-2 px-4 flex md:flex-row items-center rounded-xl flex-col gap-4">
          <img
            src="teachers/man.jpg"
            className="w-36 shadow-md object-cover h-[180px] md:h-full border mx-auto border-gray-400"
            alt=""
          />
          <div className="rounded p-2 w-full">
            <p className="text-xl font-semibold">মোহাম্মদ লুৎফর রহমান</p>
            <div className="bg-gray-300 w-full h-[1px]"></div>
            <div className="text-gray-600">
              <p>পদবী : সাধারণ শিক্ষক সদস্য</p>
              <p>মোবাইল : </p>
            </div>
          </div>
        </div>
        <div className="bg-white py-2 px-4 flex md:flex-row items-center rounded-xl flex-col gap-4">
          <img
            src="teachers/woman.jpg"
            className="w-36 shadow-md object-cover h-[180px] md:h-full border mx-auto border-gray-400"
            alt=""
          />
          <div className="rounded p-2 w-full">
            <p className="text-xl font-semibold">কল্পনা আক্তার</p>
            <div className="bg-gray-300 w-full h-[1px]"></div>
            <div className="text-gray-600">
              <p>পদবী : সংরক্ষিত মহিলা শিক্ষক সদস্য</p>
              <p>মোবাইল :</p>
            </div>
          </div>
        </div>
        <div className="bg-white py-2 px-4 flex md:flex-row items-center rounded-xl flex-col gap-4">
          <img
            src="teachers/man.jpg"
            className="w-36 shadow-md object-cover h-[180px] md:h-full border mx-auto border-gray-400"
            alt=""
          />
          <div className="rounded p-2 w-full">
            <p className="text-xl font-semibold">এস এম মাসুদ রানা</p>
            <div className="bg-gray-300 w-full h-[1px]"></div>
            <div className="text-gray-600">
              <p>পদবী : অভিভাবক সদস্য</p>
              <p>মোবাইল : </p>
            </div>
          </div>
        </div>
        <div className="bg-white py-2 px-4 flex md:flex-row items-center rounded-xl flex-col gap-4">
          <img
            src="teachers/man.jpg"
            className="w-36 shadow-md object-cover h-[180px] md:h-full border mx-auto border-gray-400"
            alt=""
          />
          <div className="rounded p-2 w-full">
            <p className="text-xl font-semibold">জহুরুল ইসলাম</p>
            <div className="bg-gray-300 w-full h-[1px]"></div>
            <div className="text-gray-600">
              <p>পদবী : অভিভাবক সদস্য</p>
              <p>মোবাইল : </p>
            </div>
          </div>
        </div>
        <div className="bg-white py-2 px-4 flex md:flex-row items-center rounded-xl flex-col gap-4">
          <img
            src="teachers/man.jpg"
            className="w-36 shadow-md object-cover h-[180px] md:h-full border mx-auto border-gray-400"
            alt=""
          />
          <div className="rounded p-2 w-full">
            <p className="text-xl font-semibold">মোঃ আব্দুর রশিদ</p>
            <div className="bg-gray-300 w-full h-[1px]"></div>
            <div className="text-gray-600">
              <p>পদবী : অভিভাবক সদস্য</p>
              <p>মোবাইল : </p>
            </div>
          </div>
        </div>
        <div className="bg-white py-2 px-4 flex md:flex-row items-center rounded-xl flex-col gap-4">
          <img
            src="teachers/man.jpg"
            className="w-36 shadow-md object-cover h-[180px] md:h-full border mx-auto border-gray-400"
            alt=""
          />
          <div className="rounded p-2 w-full">
            <p className="text-xl font-semibold">মোঃ শহিদুল ইসলাম</p>
            <div className="bg-gray-300 w-full h-[1px]"></div>
            <div className="text-gray-600">
              <p>পদবী : অভিভাবক সদস্য</p>
              <p>মোবাইল : </p>
            </div>
          </div>
        </div>
        <div className="bg-white py-2 px-4 flex md:flex-row items-center rounded-xl flex-col gap-4">
          <img
            src="teachers/woman.jpg"
            className="w-36 shadow-md object-cover h-[180px] md:h-full border mx-auto border-gray-400"
            alt=""
          />
          <div className="rounded p-2 w-full">
            <p className="text-xl font-semibold">রহিমা বেগম</p>
            <div className="bg-gray-300 w-full h-[1px]"></div>
            <div className="text-gray-600">
              <p>পদবী : সংরক্ষিত মহিলা অভিভাবক সদস্য</p>
              <p>মোবাইল : </p>
            </div>
          </div>
        </div>
        <div className="bg-white py-2 px-4 flex md:flex-row items-center rounded-xl flex-col gap-4">
          <img
            src="teachers/man.jpg"
            className="w-36 shadow-md object-cover h-[180px] md:h-full border mx-auto border-gray-400"
            alt=""
          />
          <div className="rounded p-2 w-full">
            <p className="text-xl font-semibold">বিশা মিয়া</p>
            <div className="bg-gray-300 w-full h-[1px]"></div>
            <div className="text-gray-600">
              <p>পদবী : প্রতিষ্ঠাতা সদস্য</p>
              <p>মোবাইল : </p>
            </div>
          </div>
        </div>
        <div className="bg-white py-2 px-4 flex md:flex-row items-center rounded-xl flex-col gap-4">
          <img
            src="teachers/man.jpg"
            className="w-36 shadow-md object-cover h-[180px] md:h-full border mx-auto border-gray-400"
            alt=""
          />
          <div className="rounded p-2 w-full">
            <p className="text-xl font-semibold">লিয়াকত হোসেন</p>
            <div className="bg-gray-300 w-full h-[1px]"></div>
            <div className="text-gray-600">
              <p>পদবী : দাতা সদস্য</p>
              <p>মোবাইল : </p>
            </div>
          </div>
        </div>
        <div className="bg-white py-2 px-4 flex md:flex-row items-center rounded-xl flex-col gap-4">
          <img
            src="teachers/man.jpg"
            className="w-36 shadow-md object-cover h-[180px] md:h-full border mx-auto border-gray-400"
            alt=""
          />
          <div className="rounded p-2 w-full">
            <p className="text-xl font-semibold">মোঃ জয়নুল আবেদিন</p>
            <div className="bg-gray-300 w-full h-[1px]"></div>
            <div className="text-gray-600">
              <p>পদবী : সদস্য সচিব</p>
              <p>মোবাইল : </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
