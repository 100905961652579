export default function TeachersProfile() {
  return (
    <div>
      <p className="text-xl md:text-2xl lg:text-3xl font-bold py-4 text-gray-600">
        কর্মরত শিক্ষক-কর্মচারীঃ
      </p>
      <div className="grid lg:grid-cols-2 gap-2">
        <div className="bg-white py-2 px-4 flex md:flex-row flex-col gap-4">
          <img
            src="teachers/headmaster.jpg"
            className="w-36 shadow-md object-cover h-[180px] md:h-full border mx-auto border-gray-400"
            alt=""
          />
          <div className="border rounded shadow-md p-2 w-full">
            <p className="text-xl font-semibold">মোঃ জয়নুল আবেদিন</p>
            <div className="bg-gray-300 w-full h-[1px]"></div>
            <div className="text-gray-700">
              <p>পদবী : প্রধান শিক্ষক (ভারপ্রাপ্ত)</p>
              <p>শিক্ষাগত যোগ্যতা : এম.এ , বি.এড</p>
              <p>আইডি :</p>
              <p>মোবাইল : ০১৭২২-০০১২৯৮</p>
              <p>
                ইমেইল :
                <a
                  href="mailto:joynulabedin286@gmail.com"
                  className="text-blue-600">
                  joynulabedin286@gmail.com
                </a>
              </p>
            </div>
          </div>
        </div>
        <div className="bg-white py-2 px-4 flex md:flex-row flex-col gap-4">
          <img
            src="teachers/man.jpg"
            className="w-36 shadow-md object-cover h-[180px] md:h-full border mx-auto border-gray-400"
            alt=""
          />
          <div className="border rounded shadow-md p-2 w-full">
            <p className="text-xl font-semibold">আব্দুল কাদের</p>
            <div className="bg-gray-300 w-full h-[1px]"></div>
            <div className="text-gray-700">
              <p>পদবী : সিনিয়র শিক্ষক</p>
              <p>শিক্ষাগত যোগ্যতা : বি.এস.সি, বি.এড</p>
              <p>আইডি : </p>
              <p>মোবাইল : -</p>
              <p>
                ইমেইল :
                <a href="mailto:tepablhs65@gmail.com" className="text-blue-600">
                  -
                </a>
              </p>
            </div>
          </div>
        </div>
        <div className="bg-white py-2 px-4 flex md:flex-row flex-col gap-4">
          <img
            src="teachers/ronjit.jpg"
            className="w-36 shadow-md object-cover h-[180px] md:h-full border mx-auto border-gray-400"
            alt=""
          />
          <div className="border rounded shadow-md p-2 w-full">
            <p className="text-xl font-semibold">রনজিৎ চন্দ্র ধর</p>
            <div className="bg-gray-300 w-full h-[1px]"></div>
            <div className="text-gray-700">
              <p>পদবী : সিনিয়র শিক্ষক</p>
              <p>শিক্ষাগত যোগ্যতা : বি.এ , বি.এড</p>
              <p>আইডি : </p>
              <p>মোবাইল : ০১৭২৮-৩৩৬১৪৫</p>
              <p>
                ইমেইল :
                <a
                  href="mailto:ranajitdhar986@gmail.com"
                  className="text-blue-600">
                  ranajitdhar986@gmail.com
                </a>
              </p>
            </div>
          </div>
        </div>

        <div className="bg-white py-2 px-4 flex md:flex-row flex-col gap-4">
          <img
            src="teachers/gopal.jpg"
            className="w-36 shadow-md object-cover h-[180px] md:h-full border mx-auto border-gray-400"
            alt=""
          />
          <div className="border rounded shadow-md p-2 w-full">
            <p className="text-xl font-semibold">গোপাল চন্দ্র সরকার</p>
            <div className="bg-gray-300 w-full h-[1px]"></div>
            <div className="text-gray-700">
              <p>পদবী : সিনিয়র শিক্ষক (ইংরেজী)</p>
              <p>শিক্ষাগত যোগ্যতা : বি.এ, বি.এড </p>
              <p>আইডি : </p>
              <p>মোবাইল : ০১৭১৮-৯৮৮৭১৯</p>
              <p>
                ইমেইল :
                <a
                  href="mailto:gopoalahs1977@gmail.com"
                  className="text-blue-600">
                  gopoalahs1977@gmail.com
                </a>
              </p>
            </div>
          </div>
        </div>
        <div className="bg-white py-2 px-4 flex md:flex-row flex-col gap-4">
          <img
            src="teachers/lutfor.jpg"
            className="w-36 shadow-md object-cover h-[180px] md:h-full border mx-auto border-gray-400"
            alt=""
          />
          <div className="border rounded shadow-md p-2 w-full">
            <p className="text-xl font-semibold">মোহাম্মদ লুৎফর রহমান</p>
            <div className="bg-gray-300 w-full h-[1px]"></div>
            <div className="text-gray-700">
              <p>পদবী : সহকারী শিক্ষক</p>
              <p>শিক্ষাগত যোগ্যতা : এম.কম , বি.এড</p>
              <p>আইডি : </p>
              <p>মোবাইল : ০১৭১৫-৯৮৫৪৭৮</p>
              <p>
                ইমেইল :
                <a
                  href="mailto:mohammadluthfor1977@gmail.com"
                  className="text-blue-600">
                  mohammadluthfor1977@gmail.com
                </a>
              </p>
            </div>
          </div>
        </div>
        <div className="bg-white py-2 px-4 flex md:flex-row flex-col gap-4">
          <img
            src="teachers/mosharof.jpg"
            className="w-36 shadow-md object-cover h-[180px] md:h-full border mx-auto border-gray-400"
            alt=""
          />
          <div className="border rounded shadow-md p-2 w-full">
            <p className="text-xl font-semibold">মোশাররফ হোসেন</p>
            <div className="bg-gray-300 w-full h-[1px]"></div>
            <div className="text-gray-700">
              <p>পদবী : সহকারী শিক্ষক (কৃষি)</p>
              <p>শিক্ষাগত যোগ্যতা : বি.এ, বি.এড</p>
              <p>আইডিঃ ১০০৫৫৪৫২৮</p>
              <p>মোবাইল : ০১৫৭১৭৮৭৯০৩</p>
              <p>
                ইমেইল :
                <a href="mailto:hom003987@gmail.com" className="text-blue-600">
                  hom003987@gmail.com
                </a>
              </p>
            </div>
          </div>
        </div>
        <div className="bg-white py-2 px-4 flex md:flex-row flex-col gap-4">
          <img
            src="teachers/man.jpg"
            className="w-36 shadow-md object-cover h-[180px] md:h-full border mx-auto border-gray-400"
            alt=""
          />
          <div className="border rounded shadow-md p-2 w-full">
            <p className="text-xl font-semibold">সনজিৎ চক্রবর্তী</p>
            <div className="bg-gray-300 w-full h-[1px]"></div>
            <div className="text-gray-700">
              <p>পদবী : সহকারী শিক্ষক (সাঃ বিজ্ঞান)</p>
              <p>শিক্ষাগত যোগ্যতা : বি.এ, বি.এড</p>
              <p>আইডি : </p>
              <p>মোবাইল : ---</p>
              <p>
                ইমেইল :
                <a href="mailto:tepablhs65@gmail.com" className="text-blue-600">
                  -
                </a>
              </p>
            </div>
          </div>
        </div>
        <div className="bg-white py-2 px-4 flex md:flex-row flex-col gap-4">
          <img
            src="teachers/kolpona.jpg"
            className="w-36 shadow-md object-cover h-[180px] md:h-full border mx-auto border-gray-400"
            alt=""
          />
          <div className="border rounded shadow-md p-2 w-full">
            <p className="text-xl font-semibold">কল্পনা আক্তার</p>
            <div className="bg-gray-300 w-full h-[1px]"></div>
            <div className="text-gray-700">
              <p>পদবী : সহকারী শিক্ষক (লাইব্রেরী)</p>
              <p>শিক্ষাগত যোগ্যতা : ডিপ্লোমা-ইন-লাইব্রেরী ইনফরমেশন সায়েন্স</p>
              <p>আইডি : </p>
              <p>মোবাইল : ০১৭২৭-৯৭৮৩০৫</p>
              <p>
                ইমেইল :
                <a
                  href="mailto:akterkolpona162@gmail.com"
                  className="text-blue-600">
                  akterkolpona162@gmail.com
                </a>
              </p>
            </div>
          </div>
        </div>
        <div className="bg-white py-2 px-4 flex md:flex-row flex-col gap-4">
          <img
            src="teachers/selina.jpg"
            className="w-36 shadow-md object-cover h-[180px] md:h-full border mx-auto border-gray-400"
            alt=""
          />
          <div className="border rounded shadow-md p-2 w-full">
            <p className="text-xl font-semibold">সেলিনা আক্তার</p>
            <div className="bg-gray-300 w-full h-[1px]"></div>
            <div className="text-gray-700">
              <p>পদবী : সহকারী শিক্ষক (শারীরিক শিক্ষা)</p>
              <p>শিক্ষাগত যোগ্যতা : </p>
              <p>আইডি : </p>
              <p>মোবাইল : ---</p>
              <p>
                ইমেইল :
                <a href="mailto:tepablhs65@gmail.com" className="text-blue-600">
                  -
                </a>
              </p>
            </div>
          </div>
        </div>

        <div className="bg-white py-2 px-4 flex md:flex-row flex-col gap-4">
          <img
            src="teachers/farzana.jpg"
            className="w-36 shadow-md object-cover h-[180px] md:h-full border mx-auto border-gray-400"
            alt=""
          />
          <div className="border rounded shadow-md p-2 w-full">
            <p className="text-xl font-semibold">সৈয়দা ফারজানা নাজ</p>
            <div className="bg-gray-300 w-full h-[1px]"></div>
            <div className="text-gray-700">
              <p>পদবী : সহকারী শিক্ষক (গণিত)</p>
              <p>শিক্ষাগত যোগ্যতা : বি.এ, বি.এড</p>
              <p>আইডি : </p>
              <p>মোবাইল : ---</p>
              <p>
                ইমেইল :
                <a href="mailto:tepablhs65@gmail.com" className="text-blue-600">
                  -
                </a>
              </p>
            </div>
          </div>
        </div>

        <div className="bg-white py-2 px-4 flex md:flex-row flex-col gap-4">
          <img
            src="teachers/min.jpg"
            className="w-36 shadow-md object-cover h-[180px] md:h-full border mx-auto border-gray-400"
            alt=""
          />
          <div className="border rounded shadow-md p-2 w-full">
            <p className="text-xl font-semibold">মোঃ আল-আমিন</p>
            <div className="bg-gray-300 w-full h-[1px]"></div>
            <div className="text-gray-700">
              <p>পদবী : কম্পিউটার ল্যাব অপারেটর</p>
              <p>শিক্ষাগত যোগ্যতা : ডিপ্লোমা ইন কম্পিউটার</p>
              <p>আইডি : </p>
              <p>মোবাইল : ০১৭৫৯-০৪০৫৬৮</p>
              <p>
                ইমেইল :
                <a
                  href="mailto:alamin8753000@gmail.com"
                  className="text-blue-600">
                  alamin8753000@gmail.com
                </a>
              </p>
            </div>
          </div>
        </div>
        <div className="bg-white py-2 px-4 flex md:flex-row flex-col gap-4">
          <img
            src="teachers/khaleda.jpg"
            className="w-36 shadow-md object-cover h-[180px] md:h-full border mx-auto border-gray-400"
            alt=""
          />
          <div className="border rounded shadow-md p-2 w-full">
            <p className="text-xl font-semibold">খালেদা আক্তার</p>
            <div className="bg-gray-300 w-full h-[1px]"></div>
            <div className="text-gray-700">
              <p>পদবী : অফিস সহকারী কাম কম্পিউটার অপারেটর</p>
              <p>শিক্ষাগত যোগ্যতা : এম.বি.এ</p>
              <p>আইডি : </p>
              <p>মোবাইল : ০১৭৭২-১৪৮০৪৮</p>
              <p>
                ইমেইল :
                <a
                  href="mailto:akhaleda566@gmail.com"
                  className="text-blue-600">
                  akhaleda566@gmail.com
                </a>
              </p>
            </div>
          </div>
        </div>
        <div className="bg-white py-2 px-4 flex md:flex-row flex-col gap-4">
          <img
            src="teachers/woman.jpg"
            className="w-36 shadow-md object-cover h-[180px] md:h-full border mx-auto border-gray-400"
            alt=""
          />
          <div className="border rounded shadow-md p-2 w-full">
            <p className="text-xl font-semibold">হাফিজা</p>
            <div className="bg-gray-300 w-full h-[1px]"></div>
            <div className="text-gray-700">
              <p>পদবী : অফিস সহায়ক</p>
              <p>শিক্ষাগত যোগ্যতা : এইচ.এস.সি</p>
              <p>আইডি : </p>
              <p>মোবাইল : -</p>
              <p>
                ইমেইল :
                <a
                  href="mailto:alamin875300@gmail.com"
                  className="text-blue-600">
                  alamin875300@gmail.com
                </a>
              </p>
            </div>
          </div>
        </div>
        <div className="bg-white py-2 px-4 flex md:flex-row flex-col gap-4">
          <img
            src="teachers/mominul.jpg"
            className="w-36 shadow-md object-cover h-[180px] md:h-full border mx-auto border-gray-400"
            alt=""
          />
          <div className="border rounded shadow-md p-2 w-full">
            <p className="text-xl font-semibold">মোঃ মমিনুল ইসলাম</p>
            <div className="bg-gray-300 w-full h-[1px]"></div>
            <div className="text-gray-700">
              <p>পদবী : নিরাপত্তা কর্মী</p>
              <p>শিক্ষাগত যোগ্যতা : এস.এস.সি</p>
              <p>আইডি : </p>
              <p>মোবাইল : ০১৮৩২-৯৬২৮৬১</p>
              <p>
                ইমেইল :
                <a
                  href="mailto:alamin8753000@gmail.com"
                  className="text-blue-600">
                  -
                </a>
              </p>
            </div>
          </div>
        </div>

        <div className="bg-white py-2 px-4 flex md:flex-row flex-col gap-4">
          <img
            src="teachers/sonia.jpg"
            className="w-36 shadow-md object-cover h-[180px] md:h-full border mx-auto border-gray-400"
            alt=""
          />
          <div className="border rounded shadow-md p-2 w-full">
            <p className="text-xl font-semibold">সোনিয়া আক্তার</p>
            <div className="bg-gray-300 w-full h-[1px]"></div>
            <div className="text-gray-700">
              <p>পদবী : আয়া</p>
              <p>শিক্ষাগত যোগ্যতা : জে.এস.সি</p>
              <p>আইডি : </p>
              <p>মোবাইল : -</p>
              <p>
                ইমেইল :
                <a
                  href="mailto:alamin8753000@gmail.com"
                  className="text-blue-600">
                  -
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
