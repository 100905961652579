// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyD8nhY1m9Ol25K35oItrhNQDL84Jb72Kr0",
  authDomain: "agrani-school.firebaseapp.com",
  projectId: "agrani-school",
  storageBucket: "agrani-school.appspot.com",
  messagingSenderId: "819512501178",
  appId: "1:819512501178:web:811ff6972f032e79543383",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(app);
export const storage = getStorage(app);
